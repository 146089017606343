@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-loading {
  text-align: center;
  padding: 50px 0;
  font-size: 20px;
  font-weight: bold;
}

.app-error {
  text-align: center;
  padding: 20px;
  background-color: #ffcccc;
  color: #a00;
  border: 1px solid #a00;
  margin-top: 20px;
}
.app-info {
  text-align: center;
  padding: 20px;
  background-color: #cce8ff;
  color: rgb(41, 154, 219);
  border: 1px solid rgb(102, 174, 255);
  margin-top: 20px;
}